<template>
  <v-dialog width="550" v-model="dialog" @click:outside="closeModal" persistent>
    <v-card>
      <v-card-title class="headline">{{ $t('Confirm buy request') }}</v-card-title>
      <v-card-text>
        {{ $t('The price is') }} {{ buy_price }}
        <v-alert
          class="show-here mt-4"
          border="left"
          dense
          text
          type="info"
        >
          <p>
            {{ $t('buy_now_info') }}
            <br><br>
            {{ $t('buy_now_info_commission_none') }}
          </p>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="text-none" @click="closeModal" :disabled="loading">{{ $t('No') }}</v-btn>
        <v-btn class="text-none" color="success" @click="$emit('submitBuyArticle')" :disabled="loading">{{ $t('Yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { euNumbers } from '../../services/i18n';
import { mapState } from 'pinia';
import useRootStore from '@/stores/rootStore';

export default {
  name: 'InstantBuyModal',
  props: {
    title: {},
    text: {},
    loading: Boolean
  },
  data() {
    return {
      dialog: false,
      buy_price: null
    }
  },
  computed: {
    ...mapState(useRootStore, ['appLocalization'])
  },
  methods: {
    euNumbers,
    openModal(buyPrice) {
      this.dialog = true
      this.buy_price = buyPrice
    },
    closeModal() {
      this.dialog = false
      this.$emit('clearSelectedArticle')
    }
  }
}
</script>

<style scoped lang="scss">
.btn {
  width: 140px;
}
</style>
