<template>
  <v-row class="auction-settings">
    <v-col align="center" cols="12">
      <v-form ref="form" class="pt-4">
        <div class="section-title">{{ $t('Auction settings') }}</div>
        <v-row>
          <v-col cols="4">
            <v-text-field
              :label="$t('Type')"
              class="input-field"
              placeholder=" "
              :value="$t(mutableCurrentAuction.type)"
              disabled
              @input="inputHandler"
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="mutableCurrentAuction.code"
              :label="$t('Auction code')"
              placeholder=" "
              :rules="[validationRequired()]"
              class="input-field"
              @input="inputHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <form-lang-switcher class="mt-8 mb-7" @on-changed="langChanged" />

        <v-text-field
          :label="$t('Name')"
          placeholder=" "
          :rules="[validationRequired()]"
          class="input-field"
          :value="getTranslatedText('name')"
          @input="val => setTranslatableValue('name', val)"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>

        <v-text-field
          :label="$t('Description')"
          placeholder=" "
          :rules="[validationRequired()]"
          class="input-field"
          :value="getTranslatedText('description')"
          @input="val => setTranslatableValue('description', val)"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-text-field>

        <v-row>
          <v-col cols="6" v-if="mutableCurrentAuction.type === AuctionFlavor.live">
            <vc-date-picker
              v-model="mutableCurrentAuction.start_at"
              mode="dateTime"
              is24hr
              @input="inputHandler"
            >
              <template #default="{ inputValue, togglePopover }">
                <v-text-field
                  class="bg-white text-gray-700 w-full py-2 px-0"
                  :value="inputValue"
                  @focus="togglePopover"
                  :label="$t('Start date')"
                />
              </template>
            </vc-date-picker>
          </v-col>
          <v-col :cols="mutableCurrentAuction.type === AuctionFlavor.live ? 6 : 12">
            <vc-date-picker
              v-model="mutableCurrentAuction.active_until"
              mode="dateTime"
              is24hr
              @input="inputHandler"
            >
              <template #default="{ inputValue, togglePopover }">
                <v-text-field
                  class="bg-white text-gray-700 w-full py-2 px-0"
                  :value="inputValue"
                  @focus="togglePopover"
                  :label="$t('Active until')"
                />
              </template>
            </vc-date-picker>
          </v-col>
        </v-row>

        <v-row v-if="mutableCurrentAuction.type === AuctionFlavor.timed">
          <v-col cols="6">
            <vc-date-picker
              :value="mutableCurrentAuction.timedData.active_from"
              mode="dateTime"
              is24hr
              @input="inputTimedActiveFrom"
            >
              <template #default="{ inputValue, togglePopover }">
                <v-text-field
                  class="bg-white text-gray-700 w-full py-2 px-0"
                  :value="inputValue"
                  :rules="[validationOlderThanEnd(mutableCurrentAuction)]"
                  @focus="togglePopover"
                  :label="$t('Starts at')"
                  :disabled="mutableCurrentAuction.status === AuctionStatusType.started || mutableCurrentAuction.status === AuctionStatusType.completed"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
          <v-col cols="6">
            <vc-date-picker
              :value="mutableCurrentAuction.timedData.active_until"
              mode="dateTime"
              is24hr
              @input="inputTimedActiveUntil"
            >
              <template #default="{ inputValue, togglePopover }">
                <v-text-field
                  class="bg-white text-gray-700 w-full py-2 px-0"
                  :value="inputValue"
                  :rules="[validationNewerThanStart(mutableCurrentAuction)]"
                  @focus="togglePopover"
                  :label="$t('Ends at')"
                  :disabled="mutableCurrentAuction.status === AuctionStatusType.started || mutableCurrentAuction.status === AuctionStatusType.completed"
                >
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
              </template>
            </vc-date-picker>
          </v-col>
        </v-row>

        <v-select
          class="input-field"
          :items="catalogueItems"
          :label="$t('Product catalogue')"
          v-model="mutableCurrentAuction.catalogue_enabled"
          hide-details
          @input="inputHandler"
          v-if="mutableCurrentAuction.type === AuctionFlavor.live"
        />

        <v-row>
          <v-col cols="6" class="pt-0">
            <v-checkbox
              :hide-details="true"
              class="input-field"
              v-model="mutableCurrentAuction.post_auction_sale_enabled"
              :label="$t('Post auction sale enabled')"
              @change="inputHandler"
              v-if="mutableCurrentAuction.type === AuctionFlavor.live"
            />
          </v-col>

          <v-col cols="6" class="pt-0">
            <v-checkbox
              :hide-details="true"
              class="input-field"
              v-model="mutableCurrentAuction.commission_bids_enabled"
              :label="$t('Commission bids enabled')"
              @change="inputHandler"
              v-if="mutableCurrentAuction.type === AuctionFlavor.live"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <v-text-field
              type="number"
              :label="$t('Tax %')"
              class="input-field"
              placeholder=" "
              :rules="[validationRequired(), validationPositiveNumber()]"
              v-model="mutableCurrentAuction.tax"
              min="0"
              @input="inputHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </v-col>
          <v-col col="6">
            <v-text-field
              v-if="mutableCurrentAuction.type !== AuctionFlavor.sale"
              type="number"
              :label="$t('Surcharge %')"
              class="input-field"
              placeholder=" "
              :rules="[validationRequired(), validationPositiveNumber()]"
              v-model="mutableCurrentAuction.surcharge"
              min="0"
              @input="inputHandler"
            >
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <multiple-dates
          v-if="mutableCurrentAuction.type !== AuctionFlavor.sale"
          label="Sale dates"
          v-model="mutableCurrentAuction.sale_dates"
          @inputHandler="inputHandler"
        />

        <multiple-dates
          v-if="mutableCurrentAuction.type !== AuctionFlavor.sale"
          label="Preview dates"
          v-model="mutableCurrentAuction.preview_data"
          @inputHandler="inputHandler "
          is-range
        />
        <v-textarea
          :label="$t('Terms')"
          class="input-field"
          rows="3"
          no-resize
          placeholder=" "
          :value="getTranslatedText('terms')"
          @input="val => setTranslatableValue('terms', val)"
        />
        <v-textarea
          :label="$t('Shipping data')"
          class="input-field"
          rows="3"
          no-resize
          placeholder=" "
          :value="getTranslatedText('shipping_data')"
          @input="val => setTranslatableValue('shipping_data', val)"
        />
        <v-textarea
          :label="$t('Banner image')"
          class="input-field"
          rows="1"
          no-resize
          placeholder=" "
          :rules="[validationRequired()]"
          v-model="mutableCurrentAuction.banner_image"
          @input="inputHandler"
        >
          <template #message="{ message }">
            {{ $t(message) }}
          </template>
        </v-textarea>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'pinia'
import MultipleDates from '@/components/form/multiple-dates'
import frontendValidations from '@/utils/rules';
import useRootStore from '@/stores/rootStore';
import {AuctionFlavor, AuctionStatusType} from '@/api/enums';

export default {
  name: 'AuctionSettingsForm',
  components: {
    MultipleDates
  },
  props: {
    currentAuction: Object,
    checkValidation: Boolean
  },
  data() {
    return {
      catalogueItems: [
        {
          value: 'disabled',
          text: this.$t('disabled')
        },
        {
          value: 'enabled',
          text: this.$t('enabled')
        },
        {
          value: 'enabled_with_results',
          text: this.$t('enabled with results')
        },
        {
          value: 'enabled_with_results_and_estimations',
          text: this.$t('enabled with results and estimations')
        }
      ],
      selectedLang: null,
      mutableCurrentAuction: this.currentAuction
    }
  },
  computed: {
    AuctionStatusType() {
      return AuctionStatusType
    },
    AuctionFlavor() {
      return AuctionFlavor
    },
    ...mapState(useRootStore, ['appStoreLang'])
  },
  watch: {
    checkValidation(val) {
      if (val) {
        this.inputHandler()
      }
    },
    currentAuction(val) {
      this.mutableCurrentAuction = val;
    }
  },
  methods: {
    validationRequired: frontendValidations.required,
    validationPositiveNumber: frontendValidations.positiveNumber,
    validationOlderThanEnd: frontendValidations.olderThanEnd,
    validationNewerThanStart: frontendValidations.newerThanStart,
    getFieldDate(date) {
      if (date && isNaN(Date.parse(date))) {
        return `${date.slice(6, 10)}-${date.slice(3, 5)}-${date.slice(0, 2)}T${date.slice(11, 13)}:${date.slice(14, 16)}:00.000Z`
      }
      return date
    },
    getTranslatedText(field) {
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        return this.mutableCurrentAuction[field]
      } else {
        return this.mutableCurrentAuction[`${field}_i18n`] ? this.mutableCurrentAuction[`${field}_i18n`][this.selectedLang] : ''
      }
    },
    setTranslatableValue(field, val) {
      if (!this.selectedLang || this.selectedLang === this.appStoreLang) {
        this.mutableCurrentAuction[field] = val
      } else {
        if (this.mutableCurrentAuction[`${field}_i18n`]) {
          this.mutableCurrentAuction[`${field}_i18n`] = {
            ...this.mutableCurrentAuction[`${field}_i18n`],
            [this.selectedLang]: val
          }
        } else {
          this.mutableCurrentAuction = {
            ...this.mutableCurrentAuction,
            [`${field}_i18n`]: {
              [this.selectedLang]: val
            }
          }
        }
      }
      this.inputHandler()
    },
    langChanged(lang) {
      this.selectedLang = lang
      this.$nextTick(() => {
        this.inputHandler()
      })
    },
    inputHandler() {
      this.$emit('setValidation', !this.$refs.form.validate())
    },
    inputTimedActiveFrom(e) {
      this.auction = {
        ...this.auction,
        timedData: {
          ...this.auction.timedData,
          active_from: e
        }
      }
      this.inputHandler()
    },
    inputTimedActiveUntil(e) {
      this.auction = {
        ...this.auction,
        timedData: {
          ...this.auction.timedData,
          active_until: e
        }
      }
      this.inputHandler()
    }
  }
}
</script>

<style scoped>

</style>
