import { Route } from 'vue-router';
import router from '@/router';
import dispatcher from "@/api/dispatch";
import useRootStore from '@/stores/rootStore';
import { localFeatures } from '@/utils/constants';

/**
 * Check a password, if it matches 3 out of 4 criteria
 * @param {string} str - The password
 * @return {boolean} - True if it suceeds, false if not
 */
export function validatePassword(str: string): boolean {
  let validateConditions = 0

  if (str.length < 12) {
    return false;
  }

  if (str.match(/[A-Z]/)) {
    validateConditions++;
  }

  if (str.match(/[a-z]/)) {
    validateConditions++;
  }

  if (str.match(/[\d]/)) {
    validateConditions++;
  }

  if (str.match(/[@$!%*#?&]/)) {
    validateConditions++;
  }
  return validateConditions >= 3
}

/**
 * Check a password, returns an object indicating the strngth score.
 * @param {string} password - The password
 * @return {Object} - The strength score object
 */
export function passwordStrength(password: string): object {
  if (!password || password.length <= 0) {
    return {
      color: 'red',
      value: 0
    }
  }
  const result = {
    score: 4
  }

  if (result.score === 4 && validatePassword(password)) {
    return {
      color: 'light-blue',
      value: 100
    }
  } else if (result.score >= 2 && validatePassword(password)) {
    return {
      color: 'light-green',
      value: 75
    }
  } else if (result.score >= 2) {
    return {
      color: 'yellow',
      value: 50
    }
  } else if (result.score === 1) {
    return {
      color: 'orange',
      value: 25
    }
  } else {
    return {
      color: 'red',
      value: 0
    }
  }
}

/**
 * create the object for Sentry.js and strip sensitive information
 * @param {any} object - The source object
 * @return {string} - The object (json)
 */
export function createSentryData(object: any): string {
  let result: string;
  if (object.data === undefined) {
    result = JSON.stringify(object);
  } else {
    let o = { ...object }
    o.data = { ...object.data }
    const filterList: string[] = [
      'password',
      'old_password',
      'new_password',
      'token'
    ];

    for (let key of filterList) {
      if (o.data[key] !== undefined) {
        o.data[key] = '***';
      }
    }

    result = JSON.stringify(o);
  }

  return result;
}

/**
 * sort languages alphabetically inplace
 * @param {any[]} languages - The languages
 * @return {<void>} - Nothing
 */
export const sortLanguages = (languages:any[]): void => {
  languages.sort((e1, e2) => e1.name.localeCompare (e2.name));
}

/**
 * add a query parameter
 * @param {Route} route - The route
 * @param {string} key - The parameter
 * @param {string|number} value - The value
 * @param {boolean} replaceNewURL - Flag to control if the url should be replaced instead of being pushed
 * @return {void} - Nothing
 */
export function addQueryParameter(route: Route, key: string, value: string | number, replaceNewURL: boolean): void {
  if (route.query[key] === undefined) {
    let newQuery = {...route.query};
    newQuery[key] = typeof(value) === "number" ? value.toString() : value;
    replaceNewURL ? router.replace({query: newQuery}) : router.push({query: newQuery})
  }
}

/**
 * update a query parameter
 * @param {Route} route - The route
 * @param {string} key - The parameter
 * @param {string|number} value - The value
 * @param {boolean} replaceNewURL - Flag to control if the url should be replaced instead of being pushed
 * @return {void} - Nothing
 */
export function updateQueryParameter(route: Route, key: string, value: string | number, replaceNewURL: boolean): void {
  if (route.query[key] !== undefined) {
    let newQuery = {...route.query};
    newQuery[key] = typeof(value) === "number" ? value.toString() : value;
    replaceNewURL ? router.replace({query: newQuery}) : router.push({query: newQuery})
  }
}

/**
 * Add (if it doesn't exist) or update (if it exists) a query parameter
 * @param {Route} route - The route
 * @param {string} key - The parameter
 * @param {string|number} value - The value
 * @param {boolean} replaceNewURL - Flag to control if the url should be replaced instead of being pushed
 * @return {void} - Nothing
 */
export function addOrUpdateQueryParameter(route: Route, key: string, value: string | number, replaceNewURL: boolean): void {
  if (route.query[key] !== undefined) {
    updateQueryParameter(route, key, value, replaceNewURL);
  } else {
    addQueryParameter(route, key, value, replaceNewURL);
  }
}

/**
 * delete a query parameter
 * @param {Route} route - The route
 * @param {string} key - The parameter
 * @param {boolean} replaceNewURL - Flag to control if the url should be replaced instead of being pushed
 * @return {void} - Nothing
 */
export function deleteQueryParameter(route: Route, key: string, replaceNewURL: boolean): void {
  if (route.query[key] !== undefined) {
    let newQuery = {...route.query};
    delete newQuery[key];
    replaceNewURL ? router.replace({query: newQuery}) : router.push({query: newQuery})
  }
}

/**
 * send telemetry data over the backend
 * @param {string} subject - The subject
 * @param {number} returnCode - The return code
 * @param {string} content - The content
 * @return {Promise<boolean>} - Promise with success data
 */
export async function sendTelemetry(subject: string, returnCode: number, content: string): Promise<boolean> {
  try {
    await dispatcher.sendClientTelemetry(subject, returnCode, content);
    return true;
  } catch (e) {
    console.log(`Error when sending telemetry with subject: ${subject} error: ${JSON.stringify(e)}`)
    return false;
  }
}

/**
 * get the link target, either the own, or a new tab
 * @return {<string>} - The target
 */
export function getLinkTarget(): string {
  if (useRootStore().isIframe) {
    return localFeatures.iFrameOpenLoginInNewTab ? "_blank" : "_self";
  } else {
    return "_self";
  }
}
